
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapMutations } from "vuex";

import { DroughtIndicesResponse } from "@/interfaces";
import * as types from "@/store/types";

@Component({
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    components: {
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    }
})
export default class ConjuncturalShortageTable extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    tableData = {};
    month = "2022-10";
    formattedMonth = "";
    states = [
        this.$t("droughtIndices.conjuncturalShortage"),
        this.$t("droughtIndices.prolongedDrought")
    ];

    state = this.states[0];

    //get actual month
    actualMonth() {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        this.month = `${year}-${month < 10 ? "0" + month : month}`;
    }

    mounted() {
        this.actualMonth();
        this.formatMonth();
    }

    formatMonth() {
        this.formattedMonth = this.month + "-01";
    }

    async fetchDroughtIndices() {
        try {
            this.setProgressBar(true);
            const allData = {
                type: "day",
                date: [this.formattedMonth]
            };

            const droughtIndices = await this.$api.getDroughtIndices<
                DroughtIndicesResponse
            >(allData);

            if (droughtIndices.ok) {
                this.tableData = droughtIndices.data[0];
            }
        } catch (error) {
            this.setInfoMessage({
                shown: true,
                text: this.$t("droughtIndices.noData").toString()
            });
        } finally {
            this.setProgressBar(false);
        }
    }

    /**
     * Show error message
     * @param {string} error Error message
     * @return {void}
     */
    showError(error: string): void {
        this.setInfoMessage({ shown: true, text: error });
    }
}
